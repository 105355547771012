import { useCookies } from '@vueuse/integrations/useCookies';

const cookies = useCookies();

export interface AvatarBookFavorite {
    displayName: string;
    address: string;
}

export class AvatarBookUtils {
    public static getOpenseaListingUrl(
        collectionSlug: string,
        tokenName: string,
    ) {
        return `https://opensea.io/collection/${collectionSlug}?search[query]=${encodeURI(
            tokenName,
        )}&search[sortAscending]=true&search[sortBy]=UNIT_PRICE&search[toggles][0]=BUY_NOW`;
    }

    public static scrollIntoViewWithOffset = (
        element: HTMLElement,
        offset: number,
    ) => {
        window.scrollTo({
            behavior: 'smooth',
            top:
                element.getBoundingClientRect().top +
                document.body.getBoundingClientRect().top +
                offset,
        });
    };

    public static shortenAddress(str: string, front = 6, back = 4): string {
        const strArr = str.split(':');

        if (strArr.length > 1) {
            return this.shortenMultiAddress(strArr);
        } else {
            if (this.isEthereumAddress(str)) {
                return this.shortenEthereumAddress(str, front, back);
            }
        }
        return str;
    }

    public static shortenEthereumAddress(
        str: string,
        front = 6,
        back = 4,
    ): string {
        const beginning = str.substring(0, front);
        const end = str.substring(str.length - back);
        return `${beginning}...${end}`;
    }

    public static shortenMultiAddress(strArr: string[]): string {
        const resultArr = [];
        for (const str of strArr) {
            if (this.isEthereumAddress(str)) {
                resultArr.push(this.shortenEthereumAddress(str, 0, 4));
            } else {
                resultArr.push(str);
            }
        }
        return resultArr.join(' : ');
    }

    public static isEthereumAddress = (address: string): boolean => {
        return address.length === 42 && address.slice(0, 2) == '0x';
    };

    public static loadFavorites = (): string => {
        const cookiesFavorites = cookies.get('favorites');
        if (cookiesFavorites) {
            cookies.remove('favorites');
            this.saveItem('favorites', cookiesFavorites);
            return cookiesFavorites;
        }
        const favoritesString = this.loadItem('favorites');
        return favoritesString || '';
    };

    public static saveItem(key: string, value: string): void {
        localStorage.setItem(`avatardex:${key}`, value);
    }

    public static loadItem(key: string): string {
        return localStorage.getItem(`avatardex:${key}`) || '';
    }
}
