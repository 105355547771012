import { ActiveToken } from '@/store/index';
import { OptionsState, SortCollectionOption, SortTokenOption } from '@/store/options';
import { AvatarBookAccount } from 'FirebaseTypes';
import { AvatarBookUtils } from '@/utils/AvatarBookUtils';

export const defaultOptions: OptionsState = {
    favorites: AvatarBookUtils.loadFavorites() || '',
    sortCollection:
        (AvatarBookUtils.loadItem('sortCollection') as SortCollectionOption) ||
        'valueHigh',
    sortToken:
        (AvatarBookUtils.loadItem('sortToken') as SortTokenOption) ||
        'most',
    targetCurrency: 'ETH',
    darkMode: AvatarBookUtils.loadItem('darkMode') || 'off',
    hideMissing: AvatarBookUtils.loadItem('hideMissing') || 'off',
    hideOpenseaStats: AvatarBookUtils.loadItem('hideOpenseaStats') || 'off',
    hideDuplicateAvatars:
        AvatarBookUtils.loadItem('hideDuplicateAvatars') || 'off',
    alignAvatars: AvatarBookUtils.loadItem('alignAvatars') || 'left',
    hideSeriesCount: AvatarBookUtils.loadItem('hideSeriesCount') || 'off',
    showShimmer: AvatarBookUtils.loadItem('showShimmer') || 'on',
    showMultiNews: AvatarBookUtils.loadItem('showMultiNews') || 'on',
    tokenValue: AvatarBookUtils.loadItem('tokenValue') || 'sales',
    accountDisplay: AvatarBookUtils.loadItem('accountDisplay') || 'collection',
};

export const defaultAccount: AvatarBookAccount = {
    last_updated: '',
    address: '',
    seed: '',
    uniqueTokens: '',
    releases: [],
    releasesFiltered: [],
    achievements: [],
    loadedFromCache: false,
    stats: {
        uniqueTokens: 0,
        uniqueTokensOwned: 0,
        totalTokensOwned: 0,
        uniqueCollections: 0,
        uniqueReleases: 0,
        premiumTokens: 0,
        premiumTokensOwned: 0,
        premiumUniqueTokensOwned: 0,
        accountFloorValueEth: 0,
        accountSaleValueEth: 0,
    },
};

export const defaultActiveToken: ActiveToken = {
    id: 0,
    token: {
        slug: '',
        name: '',
        image: 'QmeW8ZfmVAN6t8Ztk3AEYxycTjycUwFpRSDZArQcPhZa6f',
        collectionSlug: '',
        count: 0,
        base: 0,
        description: '',
        tokenIds: [],
        stats: {
            totalTokensOwned: 0,
            totalFloorValueEth: 0,
            totalSaleValueEth: 0,
        },
    },
    collection: {
        slug: '',
        name: '',
        contract: '',
        artistUsername: '',
        artistName: '',
        releaseSlug: '',
        tokens: [],
        stats: {
            uniqueTokens: 0,
            totalTokensOwned: 0,
            uniqueTokensOwned: 0,
            collectionFloorValueEth: 0,
            collectionSaleValueEth: 0,
        },
        openseaStats: {
            one_day_volume: 0,
            one_day_change: 0,
            one_day_sales: 0,
            seven_day_volume: 0,
            seven_day_change: 0,
            seven_day_sales: 0,
            thirty_day_volume: 0,
            thirty_day_change: 0,
            thirty_day_sales: 0,
            total_sales: 0,
            total_volume: 0,
            num_owners: 0,
            average_price: 0,
            floor_price: 0,
        },
    },
};
