import axios from 'axios';
import { Module } from 'vuex';
import { RootState } from '@/store/index';
import { AvatarBookUtils } from '@/utils/AvatarBookUtils';
import { AvatarBookAccount, AvatarBookRelease } from 'FirebaseTypes';
import { defaultAccount } from '@/store/defaults';


export const account: Module<AvatarBookAccount, RootState> = {
    state: defaultAccount,
    getters: {
        getRelease:
            (state) =>
            (releaseSlug: string): AvatarBookRelease | undefined => {
                return state.releases.find((r) => r.slug === releaseSlug);
            },
        getReleaseFiltered:
            (state) =>
            (releaseSlug: string): AvatarBookRelease | undefined => {
                return state.releasesFiltered.find(
                    (r) => r.slug === releaseSlug,
                );
            },
    },

    mutations: {
        UPDATE_ACCOUNT(state, account: AvatarBookAccount): void {
            state.last_updated = account.last_updated;
            state.address = account.address;
            state.seed = account.seed;
            state.uniqueTokens = account.uniqueTokens;
            state.releases = account.releases;
            state.achievements = account.achievements;
            state.loadedFromCache = account.loadedFromCache;
            state.stats = account.stats;
        },
        UPDATE_RELEASES_FILTERED(state, releases: AvatarBookRelease[]): void {
            state.releasesFiltered = releases;
        },
        RESET_RELEASES_FILTERED(state): void {
            state.releasesFiltered = state.releases;
        },
    },

    actions: {
        async init({ commit, state, dispatch, rootState }) {
            const addresses = rootState.accountInput.split(':');

            if (addresses.every((a) => AvatarBookUtils.isEthereumAddress(a))) {
                commit('UPDATE_BASE_ADDRESS', {
                    baseAddress: rootState.accountInput.toLowerCase(),
                });
            } else {
                await dispatch('getAddress', {
                    queryAddress: rootState.accountInput,
                });
            }

            if (!rootState.getAddressError) {
                await dispatch('getAccount');

                if (state.loadedFromCache) {
                    await dispatch('updateData');
                } else {
                    commit('DATA_UPDATED');
                }
                await dispatch('getUniqueTokens');
            }
        },
        getUniqueTokens: async function ({ commit, state }) {
            if (state.uniqueTokens) {
                console.info('actions.getUniqueTokens');
                try {
                    const uniqueTokenResponse = await axios.post(
                        `${process.env.VUE_APP_ROOT_URL}/queryUniqueTokens`,
                        { seed: state.uniqueTokens },
                    );

                    commit('UPDATE_UNIQUE_TOKENS', {
                        uniqueTokenMetadata: uniqueTokenResponse.data,
                    });
                } catch {
                    console.error('Error updating unique token data.');
                }
            } else {
                return;
            }
        },
    },
};
