import { AvatarBookCollection, AvatarBookToken } from 'FirebaseTypes';

export interface TokenCollectionPair {
    token: AvatarBookToken;
    collection: AvatarBookCollection;
}

export const CollectionFilters = {
    getPopulatedCollections: (
        collections: AvatarBookCollection[],
    ): AvatarBookCollection[] => {
        return collections.filter((c) => c.stats.totalTokensOwned > 0);
    },

    searchCollections: (
        collections: AvatarBookCollection[],
        searchText: string,
    ): AvatarBookCollection[] => {
        const searchTextLowerCase = searchText.toLowerCase();
        const matches = [];

        for (const collection of collections) {
            const collectionProperties = [
                collection.name,
                collection.slug,
                collection.artistName,
                collection.artistUsername,
            ];

            if (
                collectionProperties.some((property) =>
                    property.toLowerCase().includes(searchTextLowerCase),
                )
            ) {
                matches.push(collection);
                continue;
            }

            if (
                collection.tokens.some(
                    (token) =>
                        token.name
                            .toLowerCase()
                            .includes(searchTextLowerCase) ||
                        token.slug.toLowerCase().includes(searchTextLowerCase),
                )
            ) {
                matches.push(collection);
            }
        }
        return matches;
    },

    searchTokens: (
        pairs: TokenCollectionPair[],
        searchText: string,
    ): TokenCollectionPair[] => {
        const searchTextLowerCase = searchText.toLowerCase();
        const matches = [];

        for (const pair of pairs) {
            const pairProperties = [
                pair.collection.name,
                pair.collection.slug,
                pair.collection.artistName,
                pair.collection.artistUsername,
                pair.token.slug,
                pair.token.name,
            ];
            if (
                pairProperties.some((property) =>
                    property.toLowerCase().includes(searchTextLowerCase),
                )
            ) {
                matches.push(pair);
            }
        }
        return matches;
    },
};
