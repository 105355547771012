import { CollectionFilters } from '@/utils/CollectionFilters';
import { AvatarBookCollection } from 'FirebaseTypes';
import { BinaryOption, OptionsSetting, OptionsState } from '@/store/options';
import { NumberUtils } from '@/utils/NumberUtils';

function filterCollections(
    collections: AvatarBookCollection[],
    hideMissing: string,
): AvatarBookCollection[] {
    if (hideMissing === BinaryOption.On) {
        return CollectionFilters.getPopulatedCollections(collections);
    }
    return collections;
}

function getCollectionValue(
    collection: AvatarBookCollection,
    options: OptionsState,
): string {
    if (options[OptionsSetting.TokenValue] === 'sales') {
        return collection.stats.collectionSaleValueEth
            ? NumberUtils.formatNum(collection.stats.collectionSaleValueEth)
            : '-';
    } else {
        return collection.stats.collectionFloorValueEth
            ? NumberUtils.formatNum(collection.stats.collectionFloorValueEth)
            : '-';
    }
}

export { filterCollections, getCollectionValue };
