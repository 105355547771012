import _ from 'lodash';
import { Commit } from 'vuex';
import { filterCollections } from '@/utils/CollectionUtils';
import { CollectionFilters } from '@/utils/CollectionFilters';
import { AvatarBookRelease } from 'FirebaseTypes';

function countActiveTokens(release: AvatarBookRelease): number {
    if (!release) return 0;
    return release.collections.reduce(
        (sum, collection) =>
            collection.tokens.reduce(
                (sum, token) => token.tokenIds.length + sum,
                0,
            ) + sum,
        0,
    );
}

function filterReleases(
    releases: AvatarBookRelease[],
    filter: string,
    hideMissing: string,
) {
    const filteredReleases: AvatarBookRelease[] = [];

    for (const release of releases) {
        let filteredCollections = CollectionFilters.searchCollections(
            release.collections,
            filter,
        );

        filteredCollections = filterCollections(
            filteredCollections,
            hideMissing,
        );

        const filteredRelease = _.clone(release);
        filteredRelease.collections = filteredCollections;
        filteredReleases.push(filteredRelease);
    }
    return filteredReleases;
}

function updateFilteredReleases(
    releases: AvatarBookRelease[],
    filter: string,
    hideMissing: string,
    commit: Commit
) {
    const filteredReleases = filterReleases(
        releases,
        filter,
        hideMissing,
    );
    commit('UPDATE_RELEASES_FILTERED', filteredReleases);
}

export { countActiveTokens, filterReleases, updateFilteredReleases };
