export class NumberUtils {
    public static addCommas(num: string): string {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    public static simplify(num: number): string {
        const result = Number(num);

        if (result > 999999) {
            return (result / 1000000).toFixed(0) + 'M';
        }
        if (result > 9999) {
            return (result / 1000).toFixed(0) + 'K';
        }
        return result.toString();
    }

    public static formatNum(num: number, abbr = false): string {
        let result = this.getPrecision(num);
        const numRounded = Math.round(num);

        if (numRounded >= 1000000) {
            if (abbr) {
                result = this.toFixed((num / 1000000).toString(), 3);
                result = this.addCommas(result);
                result = `${result}M`;
            } else {
                result = num.toFixed(0);
                result = this.addCommas(result);
                result = `${result}`;
            }
        }

        if (numRounded >= 1000000000) {
            if (abbr) {
                result = this.toFixed((num / 1000000000).toString(), 3);
                result = this.addCommas(result);
                result = `${result}B`;
            } else {
                result = num.toFixed(0);
                result = this.addCommas(result);
                result = `${result}`;
            }
        }

        if (numRounded >= 1000) {
            return this.addCommas(result);
        } else {
            return result;
        }
    }

    public static getPrecision(num: number): string {
        num = Math.abs(num);
        const numRounded = Math.round(num);

        if (num > 0 && numRounded < 10) {
            return num.toPrecision(2);
        }

        if (numRounded >= 10 && numRounded < 100) {
            return num.toFixed(2);
        }

        if (numRounded >= 100 && numRounded < 1000) {
            return num.toFixed(1);
        }

        if (numRounded >= 1000 && numRounded < 10000) {
            return num.toFixed(0);
        }

        return num.toFixed(0);
    }

    public static toFixed(num: string, precision: number): string {
        const numSplit = num.split('.');
        if (!numSplit[1]) {
            return num;
        }
        let end = numSplit[1].substring(0, precision);

        while (precision - end.length > 0) {
            end = end + '0';
        }
        return numSplit[0] + '.' + end;
    }
}
