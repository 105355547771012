import { createStore } from 'vuex';
import axios from 'axios';
import { account } from '@/store/account';
import { BinaryOption, options, OptionsState } from '@/store/options';
import {
    AvatarBookAccount,
    AvatarBookCollection,
    AvatarBookToken,
    FirebaseUniqueTokenJSON,
} from 'FirebaseTypes';
import { updateFilteredReleases } from '@/utils/ReleaseUtils';

import { defaultActiveToken } from '@/store/defaults';

export type State = RootState & Modules;

export interface ActiveToken {
    id: number;
    token: AvatarBookToken;
    collection: AvatarBookCollection;
}

export interface RootState {
    activeToken: ActiveToken;
    accountInput: string;
    baseAddress: string;
    release: string;
    collectionFilter: string;
    dataLoaded: boolean;
    dataUpdated: boolean;
    dataError: boolean;
    getAddressError: boolean;
    uniqueTokenMetadata: FirebaseUniqueTokenJSON[];
    account?: AvatarBookAccount;
    options?: OptionsState;
}

export interface Modules {
    options: OptionsState;
    account: AvatarBookAccount;
}

export const store = createStore<RootState>({
    state: {
        accountInput: '',
        baseAddress: '',
        release: 'gen-one',
        activeToken: defaultActiveToken,
        collectionFilter: '',
        dataLoaded: false,
        dataUpdated: false,
        dataError: false,
        getAddressError: false,
        uniqueTokenMetadata: [],
    },

    modules: {
        options,
        account,
    },

    getters: {
        isMultiAccount: (state) => () => {
            return state.accountInput.split(':').length > 1;
        },
        getUniqueToken:
            (state) =>
            (
                id: number,
                collection: AvatarBookCollection,
            ): FirebaseUniqueTokenJSON | undefined => {
                return state.uniqueTokenMetadata.find(
                    (t: FirebaseUniqueTokenJSON) => {
                        return (
                            t.contract === collection.contract &&
                            t.id === String(id)
                        );
                    },
                );
            },

        collectionIsComplete: () => (collection: AvatarBookCollection) => {
            return collection.tokens.every(
                (token) => token.tokenIds.length > 0,
            );
        },

        getTokenName:
            (state, getters) =>
            (
                id: number,
                token: AvatarBookToken,
                collection: AvatarBookCollection,
            ): string => {
                const uniqueToken = getters.getUniqueToken(
                    id,
                    collection,
                ) as FirebaseUniqueTokenJSON;
                if (uniqueToken) {
                    return uniqueToken.name
                        ? uniqueToken.name.split('#')[0].trim()
                        : token.name;
                }
                return token.name;
            },

        getTokenImage:
            (state, getters) =>
            (
                id: number,
                token: AvatarBookToken,
                collection: AvatarBookCollection,
                thumbnail?: boolean,
            ): string => {
                const uniqueToken = getters.getUniqueToken(
                    id,
                    collection,
                ) as FirebaseUniqueTokenJSON;

                const path = thumbnail
                    ? `/assets/avatars/webp/${token.image}.webp`
                    : `/assets/avatars/webp/${token.image}.webp`;
                if (uniqueToken) {
                    if (uniqueToken.image.substring(0, 7) === 'ipfs://') {
                        return (
                            'https://ipfs.io/ipfs/' +
                            uniqueToken.image.substring(
                                7,
                                uniqueToken.image.length,
                            )
                        );
                    }
                    return uniqueToken.image || path;
                }
                return path;
            },
    },

    mutations: {
        SETUP_AVATARDEX(state, data: { accountInput: string }): void {
            state.accountInput = data.accountInput;
        },
        UPDATE_BASE_ADDRESS(state, data): void {
            state.baseAddress = data.baseAddress;
        },
        UPDATE_RELEASE(state, releaseSlug: string): void {
            state.release = releaseSlug;
        },
        UPDATE_UNIQUE_TOKENS(state, data): void {
            state.uniqueTokenMetadata = data.uniqueTokenMetadata;
        },
        DATA_LOADED(state): void {
            state.dataLoaded = true;
        },
        DATA_UPDATED(state): void {
            state.dataUpdated = true;
        },
        GET_ADDRESS_ERROR(state): void {
            state.getAddressError = true;
        },
        DATA_ERROR(state): void {
            state.dataError = true;
        },
        SET_ACTIVE_TOKEN(state, token: ActiveToken): void {
            state.activeToken = token;
        },
        RESET_ACTIVE_TOKEN(state): void {
            state.activeToken = defaultActiveToken;
        },
    },

    actions: {
        async getAddress({ commit }, payload: { queryAddress: string }) {
            try {
                const addressResponse = await axios.get(
                    `${process.env.VUE_APP_ROOT_URL}/queryAddress?account=${payload.queryAddress}`,
                );

                if (
                    addressResponse.data.baseAddress
                        .split(':')
                        .some((a: string) => a === 'ensError')
                ) {
                    commit('GET_ADDRESS_ERROR');
                } else {
                    commit('UPDATE_BASE_ADDRESS', {
                        baseAddress: addressResponse.data.baseAddress,
                    });
                }
            } catch {
                commit('DATA_ERROR');
            }
        },

        async getAccount({ state, commit }) {
            try {
                const accountResponse = await axios.get(
                    `${process.env.VUE_APP_ROOT_URL}/avatarBook?account=${state.baseAddress}`,
                );

                if (accountResponse.status === 200) {
                    commit('UPDATE_ACCOUNT', accountResponse.data);

                    const releases = state.account?.releases || [];
                    const hideMissing =
                        state.options?.hideMissing || BinaryOption.Off;

                    updateFilteredReleases(
                        releases,
                        state.collectionFilter,
                        hideMissing,
                        commit,
                    );
                    commit('DATA_LOADED');
                }
            } catch {
                commit('DATA_ERROR');
            }
        },

        async updateData({ commit, state }) {
            console.info('updating data');
            try {
                const accountResponse = await axios.get(
                    `${process.env.VUE_APP_ROOT_URL}/avatarBook?account=${state.baseAddress}&update=true`,
                );
                commit('UPDATE_ACCOUNT', accountResponse.data);
                const releases = state.account?.releases || [];
                const hideMissing =
                    state.options?.hideMissing || BinaryOption.Off;

                updateFilteredReleases(
                    releases,
                    state.collectionFilter,
                    hideMissing,
                    commit,
                );
                commit('DATA_UPDATED');
            } catch {
                console.error('Error updating account data.');
            }
        },
    },
});
