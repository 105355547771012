import { Module } from 'vuex';
import { RootState } from '@/store/index';
import { AvatarBookUtils } from '@/utils/AvatarBookUtils';
import { defaultOptions } from '@/store/defaults';

export enum OptionsSetting {
    SortCollection = 'sortCollection',
    SortToken = 'sortToken',
    TargetCurrency = 'targetCurrency',
    DarkMode = 'darkMode',
    HideMissing = 'hideMissing',
    TokenValue = 'tokenValue',
    HideOpenseaStats = 'hideOpenseaStats',
    HideDuplicateAvatars = 'hideDuplicateAvatars',
    AlignAvatars = 'alignAvatars',
    HideSeriesCount = 'hideSeriesCount',
    ShowShimmer = 'showShimmer',
    ShowMultinews = 'showMultiNews',
    Favorites = 'favorites',
    AccountDisplay = 'accountDisplay',
}

export enum AlignAvatarOption {
    Left = 'left',
    Center = 'center',
    Right = 'right',
}

export enum TokenValueOption {
    Sales = 'sales',
    Floor = 'floor'
}

export enum BinaryOption {
    On = 'on',
    Off = 'off'
}

export enum AccountDisplayOption {
    Grid = 'grid',
    Collection = 'collection'
}

export enum SortCollectionOption {
    Most = 'most',
    Least = 'least',
    AZ = 'az',
    ZA = 'za',
    ValueHigh = 'valueHigh',
    ValueLow = 'valueLow',
    DayHigh = '24hHigh',
    DayLow = '24hLow',
    WeekHigh = '7dhHigh',
    WeekLow = '7dhLow',
    VolHigh = 'volHigh',
    VolLow = 'volLow',
    OwnersHigh = 'ownersHigh',
    OwnersLow = 'ownersLow',
}

export enum SortTokenOption {
    AZ = 'az',
    ZA = 'za',
    Most = 'most',
    Least = 'least',
    FloorValueHigh = 'valueHigh',
    FloorValueLow = 'valueLow',
    SalesValueHigh = 'salesValueHigh',
    SalesValueLow = 'salesValueLow'
}


export interface OptionsState {
    [OptionsSetting.SortCollection]: string;
    [OptionsSetting.SortToken]: string;
    [OptionsSetting.TargetCurrency]: string;
    [OptionsSetting.DarkMode]: string;
    [OptionsSetting.HideMissing]: string;
    [OptionsSetting.HideOpenseaStats]: string;
    [OptionsSetting.HideDuplicateAvatars]: string;
    [OptionsSetting.AlignAvatars]: string;
    [OptionsSetting.HideSeriesCount]: string;
    [OptionsSetting.Favorites]: string;
    [OptionsSetting.ShowShimmer]: string;
    [OptionsSetting.ShowMultinews]: string;
    [OptionsSetting.TokenValue]: string;
    [OptionsSetting.AccountDisplay]: string;
}

export const options: Module<OptionsState, RootState> = {
    state: defaultOptions,

    mutations: {
        SET_OPTIONS(
            state: OptionsState,
            data: { setting: OptionsSetting; value: string },
        ): void {
            state[data.setting] = data.value;
            AvatarBookUtils.saveItem(data.setting, data.value);
        },
    },

    actions: {},
};
